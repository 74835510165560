import React from "react";
import styled from "./style.module.scss";
import { AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import footerLogo from '../../asset/logo/Eurovacancyİcon.png'
import { useTranslation } from "react-i18next";
import ReactWhatsapp from 'react-whatsapp';
const HomeFooter = () => {
const navigate = useNavigate()
const { t } = useTranslation();

const whatsappURL = ()=>{
  
}

  return (
    <>
      <footer className={styled.footer__home}>
      
        {/* <ScrollToTop onClick={whatsappURL}  /> */}
        <div className={styled.footer__container}>
          <div className={styled.footer_content}>
            <div className={styled.footer_left_body}>
              <div className={styled.footer_left}>
                <img
                  src={footerLogo}
                  alt="Logo"
                />
                <h1><span style={{color: "#202124"}}>Euro</span><span style={{color:'#1967D2'}}>Vacancy</span></h1>
              </div>
              <div className={styled.footer__bottom}>
                <div>
                  <p>{t("footer.copyright")}</p>
                  
                </div>
              </div>
            </div>
            <div className={styled.middle_body}>
            <div className={styled.footer_middle}>
              <h1>{t("footer.pages.h1")}</h1>
              <p>
                <Link
                   onClick={()=>navigate('/')}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {t("footer.pages.mainPage")}
                </Link>
              </p>
              <p>
                <Link
                   onClick={()=>navigate('/about')}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                   {t("footer.pages.about")}
                </Link>
              </p>

             
              <p>
                <Link
                  onClick={() => navigate("/contact")}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                   {t("footer.pages.contact")}
                </Link>
              </p>
            </div>
              <h3> {t("footer.pages.watchUs")}</h3>
                <div className={styled.effect}>
                  <div className={styled.buttons}>
                    <a
                      target={"_blank"}
                      href="https://www.instagram.com/euro_vacancy.az/?igshid=NGExMmI2YTkyZg%3D%3D"
                      className={styled.insta}
                      title="İnstagram-da bizə qoşulun"
                    >
                      <i>
                        <AiOutlineInstagram />
                      </i>
                    </a>
                    <a
                      target={"_blank"}
                      href="https://www.youtube.com/@eurovacancy"
                      className={styled.in}
                      title="Linkedİn-də bizə qoşulun"
                    >
                      <i>
                        <FaYoutube />
                      </i>
                    </a>
                    <a
                      target={"_blank"}
                      href="https://t.me/euro_vacancy"
                      className={styled.github}
                      title="Telegramda-da bizə qoşulun"
                    >
                      <i>
                        <BsTelegram />
                      </i>
                    </a>
                  </div>
                </div>
            </div>
            
            <div className={styled.footer_right}>
              <h1>{t("footer.contact.h1")}</h1>
              <div className={styled.social_media}>
                <p>
                  <GoLocation />
                  {t("footer.contact.location1")}
                </p>
                <p>
                  <GoLocation /> 
                  {t("footer.contact.location2")}


                </p>
                <p>
                  <GoLocation /> 
                  {t("footer.contact.location3")}


                </p>
                <p>
                  <AiOutlineMail /> info@eurovacancy.az
                </p>
                <p>
                  <BsFillTelephoneFill /> +994 (55) 896-16-00
                </p>
                
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </footer>
    </>
  );
};

export default HomeFooter;
