import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng"),
  resources: {
    en: {
      translation: {
        navbarItem: {
          about: "About",
          contact: "Contact",
          mainPage: "Home Page",
        },
        homeHeader: {
          h1: "Job Search",
          p: "Jobs & Job Search. Look for a job abroad.",
        },
        inputPlaceholder: {
          name: "Job name",
          location: "Country, City",
          company: "Company",
          find: "Search",
        },
        apply: {
          h1: "Apply for a job",
          text: "Don't be upset if you didn't find the results you were looking for. Contact us and we'll be the first to let you know when the vacancy you want is shared.",
          btn: "Apply",
          btn2: "View all jobs",
          btn3: "More",
        },
        jobsHader: {
          h1: "Active Job Vacancies",
          p: "Find a job abroad that suits you",
        },
        selection: {
          selec1: "Sort by date",
          optionDate1: "newest",
          optionDate2: "oldest",
          selec2: "Sort by salary",
          optionSalary1: "most",
          optionSalary2: "least",
        },
        joinUs: {
          h1: "Join us on Telegram",
          text: "If you want to be notified of active vacancies, join us on Telegram and be the first to know about the latest vacancies.",
          btn: "Join us on Telegram",
        },
        carusel: {
          h1: "cities where you can work",
          p: "Work in Europe's most famous cities",
        }, 
        about: {
          header: {
            h1: "Hello, Welcome to EuroVacancy!",
            p: "We are provide direct employment to people in the companies they want to work for.",
          },
          about2: {
            h1: "About Us",
            text: "As a eurovacancy company, we officially implement the recruitment process in the Republic of Poland for the vacancy chosen by the candidates. We provide support for candidates to get a 1-year work visa and a job. We are recruiting for many vacancies mainly in the manufacturing and construction sector.",
          },
          vacancy: {
            h1: "Our Vacancies",
            text: "Welder, Locksmith, Metal Structure Fitter, Painter, Plumber, Electrician, Plumber, Plasterer, Bricklayer, Moulder, Warehouse Operator, Cook's Assistant, Tailor, Truck Driver.",
          },
          obligation: {
            h1: "Obligations",
            text: " Employees of the mentioned vacancies are provided with free reception, accommodation, accommodation, work uniforms, medical services and banking services in the Republic of Poland. If accepted, the document fees and travel expenses during the documentation process are paid by the candidate. Candidates applying for employment are required to have work experience and foreign language skills (Russian or English) for the vacancy they have chosen.",
          },
          "endText":{
            "h1":"For more than 5 years, we have been providing the best experience for job seekers and employers."
          }
        },
        contact:{
          h1:"Do you have questions? Share with us",
          input:{
            inp1:{
              header:"Name, Surname",
              placeholder: "Enter first name, last name"
            },
            inp2:{
              header:"E-mail",
              placeholder:"Enter your email account"
            },
            inp3:{
              header:"Mobile number",
              placeholder:"xx xxx xx xx"
            },
            inp4:{
              header:"Message",
              placeholder:"Enter your message..."
            },
            btn:"Send",
            validation:{
              empty:'*Fill this box!'
            },
           
          }
        },
        alert:{
          success:"Your message has been successfully sent!",
          faild:'Something went wrong!'
        },
        alert2:{
          success:"Your request has been successfully sent!",
          faild:'Something went wrong!'
        },
        footer:{
          copyright:"Copyright © 2023 EuroVacancy. All rights reserved.",
          pages:{
             h1:'Pages',
             about:"About Us",
             contact:"Contact",
             mainPage:"Home Page",
             watchUs: "Watch Us",
           },
           contact:{
             h1:'Contact Information',
             location1:"Head office: Lermontov Street 109",
             location2:"Azadlig branch address: Baku city, Azadlig avenue 88",
             location3:"Education and production plant address: Alasgarov Gayibov 1222",
           }
        },
        detail:{
          h1:'About Job',
          buttons:{
            apply:"Apply",
            creatCv:"Create CV",
            uploadCv:"Upload Cv",
            close:"Close"
          },
          createCvInp:{
            title:"Create your own CV",
            jobName:"Job Name",
            companyName:"Company",
            Location:"Address",
            inp1:{
              label:"Name, Surname",
              placeholder: "enter first name, last name"
            },
            inp2:{
              label:"Age",
              placeholder:"Enter age"
            },
            inp3:{
              label: "E-mail",
              placeholder:"Enter Email"
            },
            inp4:{
              label:"Mobile number",
              placeholder:"xx xxx xx xx"
            },
            inp5:{
              label:"Language knowledge",
              placeholder:"Enter language skills in detail"
            },
            inp6:{
              label:"Your residential address",
              placeholder:"Enter your residential address"
            },
            inp7:{
              label: "Education",
              placeholder:"Enter your education level in detail"
            },
            inp8:{
              label:"Your work experience",
              placeholder:"Enter your work experience in detail"
            },
            inp9:{
              etiket:"Əvvəllər getdiyiniz ölkələr",
              placeholder:"Daha əvvəl getdiyiniz ölkələri daxil edin"
            },
            inp10:{
              etiket:"Bizim haqqımızda haradan öyrəndiniz?",
              placeholder: "Bizim haqqımızda haradan öyrənmisiniz"
            },
            btn:'Send',
            btnClose:'Close'

          }
        },
        applyCv:{
          buttons:{
            apply:"Apply",
            creatCv:"Create CV",
            uploadCv:"Upload Cv",
            close:"Close"
          },
          createCvInp:{
            title:"Create your own CV",
            jobName:"The job you are applying for",
            jobNamePlace:"Enter the name of the job.",
            Location: "Country where you want to work",
            LocationPlace: "Enter the name of the country.",
            inp1:{
              label:"Name, Surname",
              placeholder: "enter first name, last name"
            },
            inp2:{
              label:"Age",
              placeholder:"Enter age"
            },
            inp3:{
              label: "E-mail",
              placeholder:"Enter Email"
            },
            inp4:{
              label:"Mobile number",
              placeholder:"xx xxx xx xx"
            },
            inp5:{
              label:"Language knowledge",
              placeholder:"Enter language skills in detail"
            },
            inp6:{
              label:"Your residential address",
              placeholder:"Enter your residential address"
            },
            inp7:{
              label: "Education",
              placeholder:"Enter your education level in detail"
            },
            inp8:{
              label:"Your work experience",
              placeholder:"Enter your work experience in detail"
            },
            inp9:{
              label:"Countries you've been to before",
              placeholder:"Enter countries you've been to before"
            },
            inp10:{
              label:"Where did you learn about us?",
              placeholder:"Enter where did you learn about us"
            },
            btn:'Send',
            btnClose:'Close'

          }
        },
        hourSalary:'Hourly wages:'

      },
    },
    az: {
      translation: {
        navbarItem: {
          
          about: "Haqqımızda",
          contact: "Əlaqə",
          mainPage: "Ana səhifə",
        },
        homeHeader: {
          h1: "İş Axtarışı",
          p: "İşlər & İş axtar. Xaricdə iş axtar.",
        },
        inputPlaceholder: {
          name: "İşin adı",
          location: "Ölkə, Şəhər",
          company: "Şirkət",
          find: "Axtar",
        },
        apply: {
          h1: "İşə Müraciət et",
          text: "Axtardığınız nəticəni tapmadınızsa üzülməyin. Bizə müaraciət edin və istədiyiniz vakansiya paylaşıldıqda ilk olaraq sizə xəbər verəcəyik.",
          btn: "Müraciət et",
          btn2: "Bütün işlərə bax",
          btn3: "Daha çox",

        },
        jobsHader: {
          h1: "Aktiv Olan İş Vakansiyaları",
          p: "Aktiv Olan İş Vakansiyaları",
        },
        selection: {
          selec1: "Tarixə görə sırala",
          optionDate1: "ən yeni",
          optionDate2: "ən köhnə",
          selec2: "Maaşa görə sırala",
          optionSalary1: "ən çox",
          optionSalary2: "ən az",
        },
        joinUs: {
          h1: "Telegramda bizə qoşul",
          text: "Aktiv olan vakansiyaların sizə bildiriş kimi gəlməyini istəyirsinizsə telegramda bizə qoşulun, ən yeni vakansiyalardan ilk siz xəbərdar olun.",
          btn: "Telegramda bizə qoşul",
        },
        carusel: {
          h1: "İşləyə Biləcəyiniz Şəhərlər",
          p: "Avropanın ən məhşur şəhərlərində işləyin",
        },
        about: {
          header: {
            h1: "Salam, EuroVacancy'ə Xoş Gəlmisiniz!.",
            p: "Biz insanlara işləmək istədikləri şirkətlərdə birbaşa işlə təmin edirik.",
          },
          about2: {
            h1: "Haqqımızda",
            text: "Biz eurovacancy şirkəti olaraq namizədlərin seçdiyi vakansiya üzrə Polşa Respublikasında işə qəbul prosesini rəsmi olaraq həyata keçiririk. Namizədlərin 1 illik işçi vizası və iş ilə təmin olunmasına dəstək göstəririk. Əsasən istehsalat və inşaat sektoru üzrə bir çox vakansiyalar üzrə işə qəbul edirik.",
          },
          vacancy: {
            h1: "Vakansiyalarımız",
            text: "Qaynaqçı, Çilingər, Metal Konstruksiya Montajçısı, Rəngsaz, Santexnik, Elektrik, Bənna, Suvaqçı, Hörgüçü, Qəlibçi, Anbar operatoru, Aşbaz köməkçisi, Dərzi, Tır sürücüsü.",
          },
          obligation: {
            h1: "Öhdəliklər",
            text: "Qeyd edilən vakansiyalar üzrə işçilər Polşa Respublikasında ödənişsiz qarşılanma, yerləşdirilmə, yaşayış yeri, işçi formaları, tibbi xidmətlər və bank xidmətləri ilə təmin olunur. İşə qəbul olunduğu təqdirdə sənədləşmə prossesi zamanı sənədlərin rüsumları və yol xərcləri namizəd tərəfindən ödənilir. İşə qəbul üçün müraciət edən namizədlərdən seçdiyi vakansiya üzrə iş təcrübəsinin və xarici dil biliklərinin(Rus və yaxud İngilis dili) olması tələb olunur.",
          },
          "endText":{
            "h1":"5 ildən artıqdır ki, biz iş axtaranlar və işəgötürənlər üçün ən yaxşı təcrübə təqdim edirik."
          }
        },
        contact:{
          h1:"Suallarınız var? Bizimlə Bölüş",
          input:{
            inp1:{
              header:"Ad Soyad",
              placeholder:"ad,soyad daxil edin"
            },
            inp2:{
              header:"E-mail",
              placeholder:"E-mail hesabınızı daxil edin"
            },
            inp3:{
              header:"Mobil nömrə",
              placeholder:"xx xxx xx xx"
            },
            inp4:{
              header:"Mesaj",
              placeholder:"Mesajınızı daxil edin..."
            },
            btn:"Göndər",
            validation:{
              empty:'*Bu xananı doldurun!',
           
            }
          }
        },
        alert:{
          success:"Mesajınız uğurla göndərildi!",
          faild:'Xəta baş verdi'
        },
        alert2:{
          success:"Müraciətiniz uğurla göndərildi!",
          faild:'Xəta baş verdi'
        },
        footer:{
          copyright:"Copyright © 2023 EuroVacancy. Bütün hüquqlar qorunur.",
          pages:{
            h1:'Səhifələr',
            about:"Haqqımızda",
            contact:"Əlaqə",
            mainPage:"Ana səhifə",
            watchUs:"Bizi izlə",
          },
          contact:{
            h1:'Əlaqə məlumatı',
            location1:"Baş ofis:Lermontov küçəsi 109",
            location2:"Azadlıq filialı ünvanı:Bakı Şəhəri, Azadlıq prospekti 88",
            location3:"Tədris və istehsal zavodu ünvanı:Ələsgərov Qayıbov 1222",
          }
        },
        detail:{
          h1:'İş Haqqında',
          buttons:{
            apply:"Müraciət et",
            creatCv:"CV Yarat",
            uploadCv:"Cv Yüklə",
            close:"Bağla"
          },
          createCvInp:{
            title:"Öz CV'nizi yaradın",
            jobName:"Müraciət etdiyiniz iş",
            companyName:"Müraciət etdiyiniz şirkət",
            Location:"İşləmək istədiyiniz ölkə",
            inp1:{
              label:"Ad, Soyad",
              placeholder:"ad, soyad daxil edin"
            },
            inp2:{
              label:"Yaş",
              placeholder:"Yaşı daxil"
            },
            inp3:{
              label:"E-mail",
              placeholder:"E-mail daxil edin"
            },
            inp4:{
              label:"Mobil nömrə",
              placeholder:"xx xxx xx xx"
            },
            inp5:{
              label:"Dil biliyi",
              placeholder:"Dil biliyini ətraflı şəkildə daxil edin"
            },
            inp6:{
              label:"Yaşayış ünvanınız",
              placeholder:"Yaşayış ünvanınızı daxil edin"
            },
            inp7:{
              label:"Təhsil",
              placeholder:"Təhsil səviyyənizi ətraflı şəkild daxil edin"
            },
            inp8:{
              label:"İş təcrübəniz",
              placeholder:"İş təcrübənizi ətraflı şəkild daxil edin"
            },
            btn:'Göndər',
            btnClose:'Bağla'
          }
        },
        applyCv:{
          buttons:{
            apply:"Müraciət et",
            creatCv:"CV Yarat",
            uploadCv:"Cv Yüklə",
            close:"Bağla"
          },
          createCvInp:{
            title:"Öz CV'nizi yaradın",
            jobName:"Müraciət etdiyiniz iş",
            jobNamePlace:"İşin adını daxil edin.",
            Location:"İşləmək istədiyiniz ölkə",
            LocationPlace:"Ölkənin adını daxil edin." ,
            inp1:{
              label:"Ad, Soyad",
              placeholder:"ad, soyad daxil edin"
            },
            inp2:{
              label:"Yaş",
              placeholder:"Yaşı daxil"
            },
            inp3:{
              label:"E-mail",
              placeholder:"E-mail daxil edin"
            },
            inp4:{
              label:"Mobil nömrə",
              placeholder:"xx xxx xx xx"
            },
            inp5:{
              label:"Dil biliyi",
              placeholder:"Dil biliyini ətraflı şəkildə daxil edin"
            },
            inp6:{
              label:"Yaşayış ünvanınız",
              placeholder:"Yaşayış ünvanınızı daxil edin"
            },
            inp7:{
              label:"Təhsil",
              placeholder:"Təhsil səviyyənizi ətraflı şəkild daxil edin"
            },
            inp8:{
              label:"İş təcrübəniz",
              placeholder:"İş təcrübənizi ətraflı şəkild daxil edin"
            },
            inp9:{
              label:"Əvvəllər getdiyiniz ölkələr",
              placeholder:"Əvvəllər getdiyiniz ölkələri daxil edin"
            },
            inp10:{
              label:"Bizi haradan öyrəndiniz?",
              placeholder:"Bizi haradan öyrəndiyinizi daxil edin"
            },
            btn:'Göndər',
            btnClose:'Bağla'
          }
        },
        hourSalary:'Saatlıq Ə/H:'

        
      },
    },
    fallbackLng: "az",
    debug: true,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
