import React, {  useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HomeHeader from "../../components/HomeHeader";
import styled from "./style.module.scss";
import Carusel from "../../components/Carusel";
import HomeFooter from "../../components/HomeFooter";
import { getAllQuestion, getAllQuestionEn } from "../../apicalls/exmas";
import { message } from "antd";
import { IoMdAdd } from "react-icons/io";
import { BsTelegram } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineExpandMore } from "react-icons/md"; //pul
import { useNavigate } from "react-router-dom";
import imageSectionOne from '../.././asset/euroImage/1.jpg'
import imageSectionTwo from '../.././asset/euroImage/Euro.jpg'
import { useTranslation } from 'react-i18next';
import ReactWhatsapp from "react-whatsapp";
const Home = () => {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [sortByDate, setSortByDate] = useState("newest");
  const [sortByPrice, setSortByPrice] = useState("lowToHigh");
  const [jobLocation, setJobLocation] = useState("");
  const { t } = useTranslation();


  const handleChangeLocation = (event) => {
    setJobLocation(event.target.value);
  };
  exams.filter((data) => {
    if (jobLocation === "") {
      return data;
    } else if (
      data.name.toLowerCase().includes(jobLocation.toLocaleLowerCase())
    ) {
      return data;
    }
  });
  const slectorvaluHome = localStorage.getItem('i18nextLng')
 
  const getExams = async () => {
    try {
      // dispatch(ShowLoading());
      var response;
      if(slectorvaluHome === 'az'){

        response = await getAllQuestion();
        if (response && response.success) {
          let sorted = [...response.data];
  
          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          } else {
            sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          }
  
          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }
  
          setExams(sorted);
        } else {
          message.error(response.message);
        }
      }
      else if (slectorvaluHome === 'en'){
        response = await getAllQuestionEn()
        if (response && response.success) {
          let sorted = [...response.data];
  
          // For Date Sort
          if (sortByDate === "newest") {
            sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          } else {
            sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          }
  
          // For Salary Sort
          if (sortByPrice === "lowToHigh") {
            sorted.sort((a, b) => parseInt(a.salary) - parseInt(b.salary));
          } else {
            sorted.sort((a, b) => parseInt(b.salary) - parseInt(a.salary));
          }
  
          setExams(sorted);
        } else {
          message.error(response.message);
        }
      }
      
      // dispatch(HideLoading());
    } catch (error) {
      // dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getExams();
  }, [sortByDate, sortByPrice]);
  const getJobDetail = (id) => {
    navigate(`/detail/${id}`);
  };
  const [jobName, setJobName] = useState(""); // İş adı arama terimi
  const [location, setLocation] = useState(""); // Lokasyon arama terimi
  const [companyName, setCompanyName] = useState(""); // Şirket adı arama terimi
  const [visibleDataCount, setVisibleDataCount] = useState(8);
  const handleJobNameSearch = (event) => {
    setJobName(event.target.value);
  };

  const handleLocationSearch = (event) => {
    setLocation(event.target.value);
  };

  const handleCompanyNameSearch = (event) => {
    setCompanyName(event.target.value);
  };
  const handleSearchButtonClick = () => {
    // Arama işlemleri yapabilirsiniz...
    // Bu kısımda inputlardan alınan değerleri kullanarak arama işlemi gerçekleştirilebilir.

    // Input değerlerini sıfırlayalım
    setJobName("");
    setLocation("");
    setCompanyName("");
  };

  const filteredData = exams && exams.filter((data) => {
    return (
        data.name.toLowerCase().includes(jobName.toLowerCase()) &&
       data.location.toLowerCase().includes(location.toLowerCase()) 
      // data? data.compName.toLowerCase().includes(companyName.toLowerCase())
    );
  });

  const displayData = jobName || location || companyName ? filteredData : exams;
  const totalDataCount = displayData.length;

  const loadMoreData = () => {
    setVisibleDataCount((prevCount) => prevCount + 8);
  };
  const jobSectionRef = useRef(null);

  const handleAraButtonClick = () => {
    if (jobSectionRef.current) {
      jobSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleSelect = (date) => {
  //   let filtered = exams.filter((exam) => {
  //     let examDate = new Date(exam.createdAt);
  //     return (examDate >= date.selection.startDate && examDate <= date.selection.endDate);
  //   });

  //   setSortByDate(''); // Bu satırı ekleyerek, date range seçimi değiştiğinde sortByDate'i sıfırlıyoruz.
  //   setExams(filtered);
  // };

  const handleSortByDateChange = (e) => {
    setSortByDate(e.target.value);
  };
  const handleSortByPriceChange = (e) => {
    setSortByPrice(e.target.value);
  };
  return (
    <div>
      <section>
        <div className={styled.header}>
          <HomeHeader />
          <div className={styled.header_right}>
            <div className={styled.header_text}>
              <h2>{t("homeHeader.h1")}</h2>
              <p>
              {t("homeHeader.p")}
              </p>
              <div className={styled.searchPanel}>
                <div className={styled.search_input}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19.7722 18.6726L14.8912 13.7917C16.1044 12.3328 16.8354 10.4593 16.8354 8.41797C16.8354 3.77628 13.0592 0 8.41774 0C3.77617 0 0 3.77628 0 8.41797C0 13.0593 3.77617 16.8353 8.41774 16.8353C10.459 16.8353 12.3325 16.1045 13.7915 14.8912L18.6725 19.7722C18.8243 19.9241 19.0234 20 19.2224 20C19.4213 20 19.6204 19.9241 19.7723 19.7722C20.0759 19.4685 20.0759 18.9763 19.7722 18.6726ZM1.55517 8.41797C1.55517 4.63381 4.63369 1.55518 8.41774 1.55518C12.2017 1.55518 15.2801 4.63381 15.2801 8.41797C15.2801 12.2018 12.2017 15.2801 8.41774 15.2801C4.63369 15.2801 1.55517 12.2018 1.55517 8.41797Z"
                      fill="#696969"
                    />
                  </svg>
                  <input
                    value={jobName}
                    onChange={handleJobNameSearch}
                    type="text"
                    placeholder={t("inputPlaceholder.name")}
                  />
                </div>
                <div className={styled.search_input}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="24"
                    viewBox="0 0 19 24"
                    fill="none"
                  >
                    <path
                      d="M9.89025 0C4.92777 0 0.890625 4.03769 0.890625 9.0007C0.890625 12.547 2.37532 16.1342 5.18422 19.3745C7.28218 21.7948 9.36132 23.166 9.44888 23.2232C9.58292 23.3109 9.73664 23.3547 9.89036 23.3547C10.044 23.3547 10.1977 23.3109 10.3318 23.2232C10.4193 23.166 12.4987 21.7948 14.5967 19.3747C17.4058 16.1342 18.8906 12.547 18.8906 9.0007C18.8905 4.03769 14.8529 0 9.89025 0ZM9.89025 21.5573C8.23505 20.3239 2.50419 15.577 2.50419 9.0007C2.50419 4.92741 5.81749 1.61357 9.89025 1.61357C13.9633 1.61357 17.2769 4.92741 17.2769 9.0007C17.2769 15.577 11.5456 20.3239 9.89025 21.5573Z"
                      fill="#696969"
                    />
                    <path
                      d="M9.89037 5.42688C7.92074 5.42688 6.31836 7.02937 6.31836 8.99921C6.31836 10.9685 7.92074 12.5707 9.89037 12.5707C11.86 12.5707 13.4623 10.9685 13.4623 8.99921C13.4623 7.02948 11.8599 5.42688 9.89037 5.42688ZM9.89037 10.9571C8.81046 10.9571 7.93193 10.0788 7.93193 8.99921C7.93193 7.91909 8.81046 7.04045 9.89037 7.04045C10.9702 7.04045 11.8487 7.91909 11.8487 8.99921C11.8487 10.0788 10.9702 10.9571 9.89037 10.9571Z"
                      fill="#696969"
                    />
                  </svg>
                  <input
                    value={location}
                    onChange={handleLocationSearch}
                    type="text"
                    placeholder={t("inputPlaceholder.location")}
                  />
                </div>
                <div className={styled.search_input}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_124_2837)">
                      <path
                        d="M17.4727 4.23633H12.7046V3.18839C12.7046 2.01428 11.7494 1.05908 10.5753 1.05908H7.42475C6.25068 1.05908 5.29545 2.01428 5.29545 3.18839V4.23633H0.527344C0.236109 4.23633 0 4.47244 0 4.76367V14.3438C0 15.7758 1.16511 16.9409 2.59717 16.9409H15.4028C16.8349 16.9409 18 15.7758 18 14.3438V4.76367C18 4.47244 17.7639 4.23633 17.4727 4.23633ZM10.1965 11.1182C10.1965 11.7779 9.65974 12.3147 9 12.3147C8.34026 12.3147 7.80346 11.7779 7.80346 11.1182V9.92162H10.1965V11.1182ZM10.7239 8.86693H7.27611C6.98488 8.86693 6.74877 9.10304 6.74877 9.39428V10.3522C3.68216 9.6866 1.38333 7.69753 1.08756 5.29102H16.9124C16.6166 7.69753 14.3178 9.6866 11.2512 10.3522V9.39431C11.2512 9.10304 11.0151 8.86693 10.7239 8.86693ZM6.3501 3.18839C6.3501 2.59583 6.8322 2.11377 7.42472 2.11377H10.5752C11.1678 2.11377 11.6499 2.59583 11.6499 3.18839V4.23633H6.35006V3.18839H6.3501ZM16.9453 14.3438C16.9453 15.1943 16.2533 15.8862 15.4028 15.8862H2.59717C1.74667 15.8862 1.05469 15.1943 1.05469 14.3438V8.01049C1.48043 8.61929 2.02936 9.18148 2.69251 9.67883C3.84166 10.5407 5.24457 11.139 6.77141 11.4343C6.92561 12.5264 7.86596 13.3694 9 13.3694C10.134 13.3694 11.0744 12.5264 11.2286 11.4343C12.7554 11.139 14.1583 10.5407 15.3075 9.67883C15.9706 9.18148 16.5196 8.61926 16.9453 8.01049V14.3438Z"
                        fill="#696969"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_124_2837">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input
                    value={companyName}
                    onChange={handleCompanyNameSearch}
                    type="text"
                    placeholder={t("inputPlaceholder.company")}
                  />
                </div>
                <button onClick={handleAraButtonClick}>{t("inputPlaceholder.find")}</button>
              </div>
            </div>
          </div>
          <div className={styled.headeLeft}>

          </div>
        </div>
      </section>
          <ReactWhatsapp className={styled.whatsapp} number="994-55-896-16-00" message="Salam,hər vaxtınız xeyir." width={"100px"} height={"100px"} ><IoLogoWhatsapp/></ReactWhatsapp>
      {/* <section id="learning">
        <div className={styled.learning_section}>
          <div className={styled.contanier}>
            <div className={styled.learning_section_left}>
              <h2>İndi EuroVacancy-də işə götürən ən yaxşı şirkətlər</h2>

            </div>
            <div className={styled.learning_section_right}>
              <img src={image1} />
              <img src={image2} />
              <img src={image3} />
              <img src={image4} />
              <img src={image5} />
              <img src={image6} />
              <img src={image5} />
            </div>
          </div>
        </div>
      </section> */}
      <section id="accesibility">
        <div className={styled.activity_section}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionOne} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("apply.h1")}</h2>
              <p>
              {t("apply.text")}
              </p>
              <button onClick={() => navigate("/apply")}>
                <IoMdAdd />
                {t("apply.btn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section ref={jobSectionRef} className={styled.jobs_section}>
        <div className={styled.contanier}>
          <div>
            <h2>{t("jobsHader.h1")}</h2>
            <p className={styled.job_section_headp}>
             {t("jobsHader.p")}
            </p>
            {displayData && displayData.length > 0 ? (
              <div className={styled.select_body}>
                <div className={styled.select_itemBody}>
                  <p>{t("selection.selec1")}</p>
                  <div className={styled.select}>
                    <select
                      value={sortByDate}
                      onChange={handleSortByDateChange}
                    >
                      <option value="newest">{t("selection.optionDate1")}</option>
                      <option value="oldest">{t("selection.optionDate2")}</option>
                    </select>
                  </div>
                </div>
                <div className={styled.select_itemBody}>
                  <p>{t("selection.selec2")}</p>
                  <div className={styled.select}>
                    <select
                      id="salary"
                      value={sortByPrice}
                      onChange={handleSortByPriceChange}
                    >
                      <option value="lowToHigh">{t("selection.optionSalary2")}</option>
                      <option value="highToLow">{t("selection.optionSalary1")}</option>
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className={styled.cards_body}>
              {displayData && displayData.length > 0 ? (
                displayData.slice(0, visibleDataCount).map((data) => {
                  return (
                    <div key={data._id} className={styled.card}>
                      <img
                        className={styled.card_jobImage}
                        src={data.category}
                        alt="job Image"
                      />
                      <div>
                        <p className={styled.card_companyName}>
                          {data.compName}
                        </p>
                        <p className={styled.card_jobTitle}>{data.name}</p>
                      </div>
                      {/* <p className={styled.card_jobLocation}>
                        <CiLocationOn />
                        {data.location}
                      </p> */}
                      <p className={styled.card_jobLocation}>
                        {/* <RiMoneyEuroCircleLine /> */}
                        <strong>{t("hourSalary")}</strong>{data.salary} ₼
                      </p>
                      <button onClick={() => getJobDetail(data._id)}>
                        <IoMdAdd />
                        <p>{t("apply.btn")}</p>
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className={styled.emptyData_body}>
                  {/* <img src={emptyData}/> */}
                  <p>Axtardığınız nəticə tapılmadı</p>
                </div>
              )}
            </div>
            <div className={styled.loadMore_body}>
              {displayData.length > 0 ? (
                displayData.length > 7 ? (
                  <button onClick={loadMoreData}>
                    <MdOutlineExpandMore />
                    <p>{t("apply.btn3")}</p>

                  </button>
                ) : // Eğer displayData varsa ve length 7'den küçük veya eşitse bir şey gösterme
                null
              ) : (
                // Eğer displayData yoksa "Bütün işleri gör" butonunu göster
                <button onClick={handleSearchButtonClick}>
                <p>{t("apply.btn2")}</p>

                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <section id="accesibility">
        <div className={styled.activity_section_left_body}>
          <div className={styled.activity_section_left}>
            <div>
              <h2>I am Recruiter</h2>
              <p>
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </p>
              <button onClick={()=> window.open('http://t.me/kanans')}>
                <BsTelegram />
                Telegramda bizə qoşul
              </button>
            </div>
          </div>
          <div className={styled.activity_section_right}>
            <img src="https://mykaleidoscope.ru/x/uploads/posts/2022-10/1666235211_15-mykaleidoscope-ru-p-uvlekayushchiisya-chelovek-oboi-19.jpg" />
          </div>
        </div>
      </section> */}
      <section id="accesibility">
        <div className={styled.activity_section2}>
          <div className={styled.activity_section_right}>
            <img src={imageSectionTwo} />
          </div>

          <div className={styled.activity_section_left}>
            <div>
              <h2>{t("joinUs.h1")}</h2>
              <p>
              {t("joinUs.text")}
              </p>
              <button onClick={() => window.open("https://t.me/euro_vacancy")}>
                <BsTelegram />
                {t("joinUs.btn")}
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={styled.company_section}>
        <div className={styled.container}>
          <h2 className={styled.companyHeader}>Top Company Registered</h2>
          <h2 className={styled.companyParagraph}>
            Some of the companies we've helped recruit excellent applicants over
            the years.
          </h2>
          <div className={styled.cards_body}>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
            <div className={styled.card}>
              <div className={styled.card_left}>
                <img src="https://w.forfun.com/fetch/cc/cc6760a4d3682a851101bffc5616510d.jpeg" />
                <div className={styled.card_left_text}>
                  <h2>Udemy</h2>
                  <p>
                    <CiLocationOn /> London
                  </p>
                </div>
              </div>
              <div className={styled.card_right}>15 Open Position</div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="activity">
        <div className={styled.carusel_body}>
          <Carusel />
        </div>
      </section>

      {/* <section id="contact">
         <Contact/>
      </section> */}

      <section>
        <HomeFooter />
      </section>
    </div>
  );
};
export default Home;
